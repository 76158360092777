// src/components/Series/PlayerSeries.js
import React, { useState, useEffect, useRef, useCallback, Suspense, lazy } from 'react';
import styled from 'styled-components';
import { useParams, useHistory } from "react-router-dom";
import { optimizeName } from "../../other/vod-series-name-optimizer";
import { useSelector, shallowEqual } from "react-redux";
import DB from "../../other/local-db";
import ErrorBoundary from './ErrorBoundary'; // Provjerite putanju

const ReactNetflixPlayer = lazy(() => import("../../other/Player-github/player-github"));

const Container = styled.div`
  position: absolute; /* Koristi position: absolute za full-screen */
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: black;

  *:focus {
    outline: none; /* Uklanja fokusnu konturu */
  }
`;

const StyledReactNetflixPlayer = styled(ReactNetflixPlayer)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const PlayerSeries = () => {
  const { category, season, episode, stream_id } = useParams();
  const history = useHistory();

  const tvseries = useSelector(
    state => state.playlist?.find(x => parseInt(x.series_id) === parseInt(stream_id))?.name || "Unknown Series",
    shallowEqual
  );

  const streamsTemp = useSelector(
    state => state.playlistEpisodes,
    shallowEqual
  ) || [];

  const [stream, setStream] = useState(null);
  const [streams, setStreams] = useState([]);
  const [streamStat, setStreamStat] = useState({});
  const [isReloading, setIsReloading] = useState(false);
  const isMounted = useRef(true);
  const [fallbackToHtml5Player, setFallbackToHtml5Player] = useState(false);

  useEffect(() => {
    if (!streamsTemp || streamsTemp.length === 0) {
      console.error("No episodes found. Redirecting to series list.");
      history.replace(`/series/`);
      return;
    }
    const updatedStreams = streamsTemp.map(x => ({
      ...x,
      playing: parseInt(x.episode_num || x.episode) === parseInt(episode),
      id: x.episode_num || x.episode,
      name: x.title || `Episode ${x.episode}`
    }));
    if (isMounted.current) setStreams(updatedStreams);
  }, [streamsTemp, episode, history]);

  useEffect(() => {
    if (streams.length > 0) {
      const selectedStream = streams.find(x => x.playing === true);
      let stat = DB.findOne("series", stream_id) || { id: stream_id, season, episode: selectedStream?.id, start: 0, tot: 0 };
      if (stat && (parseInt(stat.season) !== parseInt(season) || parseInt(stat.episode) !== parseInt(episode))) {
        stat = { id: stream_id, season, episode: selectedStream?.id, start: 0, tot: 0 };
      }
      setStreamStat(stat);
      DB.set("series", stream_id, stat);
      setStream(selectedStream || {});
    }
  }, [streams, season, episode, stream_id]);

  const setStat = useCallback((stat) => {
    setStreamStat(stat);
    DB.set("series", stream_id, stat);
  }, [stream_id]);

  const handleEpisodeChange = useCallback((id) => {
    const next = streams.find(x => x.episode_num === id);
    if (next) {
      setIsReloading(true);
      history.replace(`/series/category/${category}/${stream_id}/play/season/${season}/episode/${next.episode_num}/`);
      setTimeout(() => setIsReloading(false), 0);
    }
  }, [streams, history, category, stream_id, season]);

  if (isReloading) return null;

  return (
    <Container>
      <ErrorBoundary>
        <Suspense fallback={<div style={{ color: 'white', textAlign: 'center', padding: '2rem' }}>Loading Player...</div>}>
          {stream && (
            fallbackToHtml5Player ? (
              <video
                src={stream.direct_source || stream.url || stream.url2}
                controls
                autoPlay
                style={{ width: '100%', height: '100%' }}
                onEnded={() => {
                  const next = streams.find(x => x.episode_num > stream.episode_num);
                  if (!next) history.goBack();
                  else history.replace(`/series/category/${category}/${stream_id}/play/season/${season}/episode/${next.episode_num}/`);
                }}
              />
            ) : (
              <StyledReactNetflixPlayer
                src={stream.direct_source || stream.url || stream.url2}
                title={optimizeName(tvseries)}
                titleMedia={optimizeName(tvseries)}
                extraInfoMedia={optimizeName(stream?.title || "Unknown Episode")}
                backButton={() => history.goBack()}
                fullPlayer
                autoPlay
                startPosition={streamStat?.start || 0}
                overlayEnabled
                autoControllCloseEnabled
                primaryColor="var(--second-color)"
                secundaryColor="var(--first-color)"
                reprodutionList={streams.map((s, idx) => ({ ...s, key: `stream-${s.id}-${idx}` }))}
                playlistTitle={`Season ${season}`}
                syncDuration={(duration, percentage) => setStat({ ...streamStat, start: duration, tot: parseInt(percentage), episode, season })}
                dataNext={() => {
                  const next = streams.find(x => x.episode_num > stream.episode_num);
                  return { title: next ? `Next: ${next?.title}` : "" };
                }}
                onClickItemListReproduction={handleEpisodeChange}
                onEnded={() => {
                  const next = streams.find(x => x.episode_num > stream.episode_num);
                  if (!next) history.goBack();
                  else history.replace(`/series/category/${category}/${stream_id}/play/season/${season}/episode/${next.episode_num}/`);
                }}
                onErrorVideo={() => setFallbackToHtml5Player(true)}
              />
            )
          )}
        </Suspense>
      </ErrorBoundary>
    </Container>
  );
};

export default PlayerSeries;