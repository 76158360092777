// src/components/NavBar.js

import React, { useState, useCallback, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAuth } from '../other/auth';
import { FaHome, FaSearch, FaGripLines, FaEllipsisV } from 'react-icons/fa';
import PropTypes from 'prop-types';

// Styled Components
const Nav = styled.nav`
  background-color: #141414;
  display: flex;
  flex-direction: column; /* Organize into two rows */
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.7);
  min-height: 3.5rem;

  @media (max-width: 600px) {
    padding: 0 1rem;
  }
`;

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

const Separator = styled.hr`
  width: 100%;
  border: none;
  border-top: 0px solid #333;
  margin: 0.1rem 0;
`;

// Styled Components for FilterInfo
const LabelSpan = styled.span`
  color: #696565; /* White color for labels */
  font-weight: normal;
`;

const ValueSpan = styled.span`
  color: #e50914; /* Red color for values */
  font-weight: normal;
`;

const FilterInfo = styled.div`
  width: 100%; /* Full width */
  display: flex;
  justify-content: flex-start; /* Align to the left */
  align-items: center;
  color: rgba(255, 255, 255, 0.95);
  font-size: 1.2rem;
  background-color: rgba(20, 20, 20, 0.95);
  padding: 0.8rem 0;
  gap: 0.5rem; /* Space between labels */

  @media (max-width: 600px) {
    font-size: 0.8rem;
    gap: 1rem;
  }
`;

const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 1.5rem;
  text-decoration: none;
  padding: 0.5rem;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  background: transparent;

  &:hover,
  &:focus {
    background-color: #e50914;
    outline: none;
  }
`;

const ContainerButton = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;

  @media (max-width: 600px) {
    gap: 1rem;
  }
`;

const StyledButton = styled.button`
  background: transparent;
  color: #fff;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  text-decoration: none;
  border: none;
  cursor: pointer;
  outline: none;

  &:hover,
  &:focus {
    background-color: #e50914;
    outline: none;
  }

  @media (max-width: 600px) {
    font-size: 0.9rem;
    padding: 0.5rem;
  }
`;

const Button = styled(Link)`
  position: relative;
  background: transparent;
  color: #fff;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  text-decoration: none;

  &:hover,
  &:focus {
    background-color: #e50914;
    outline: none;
  }
`;

const Modal = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.8);
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const List = styled.ul`
  background: #1c1c1c;
  color: #fff;
  list-style: none;
  padding: 1rem;
  border-radius: 8px;
  max-height: 50vh;
  overflow-y: auto;
`;

const ListItem = styled.li`
  padding: 0.5rem;
  cursor: pointer;

  &:hover,
  &:focus {
    background: #e50914;
    outline: none;
  }
`;

// NavBar Component
const NavBar = ({ onSortToggle, sortMode, onGenreChange, selectedGenre, currentCategory, showFilterInfo }) => {
  const location = useLocation();
  const auth = useAuth();
  const history = useHistory();
  const buttonsRef = useRef([]);
  const genreListRef = useRef([]);
  const sortListRef = useRef([]);
  const [focusedIndex, setFocusedIndex] = useState(0);
  const [isNavBarFocused, setIsNavBarFocused] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSortModalOpen, setIsSortModalOpen] = useState(false);
  const [focusedGenreIndex, setFocusedGenreIndex] = useState(0);
  const [focusedSortIndex, setFocusedSortIndex] = useState(0);

  const genres = [
    { id: 28, name: "Action", hrName: "Akcija" },
    { id: 12, name: "Adventure", hrName: "Avantura" },
    { id: 16, name: "Animation", hrName: "Animacija" },
    { id: 35, name: "Comedy", hrName: "Komedija" },
    { id: 80, name: "Crime", hrName: "Kriminalistički" },
    { id: 99, name: "Documentary", hrName: "Dokumentarni" },
    { id: 18, name: "Drama", hrName: "Drama" },
    { id: 10751, name: "Family", hrName: "Porodični" },
    { id: 14, name: "Fantasy", hrName: "Fantazija" },
    { id: 36, name: "History", hrName: "Istorijski" },
    { id: 27, name: "Horror", hrName: "Horor" },
    { id: 10402, name: "Music", hrName: "Muzika" },
    { id: 9648, name: "Mystery", hrName: "Misterija" },
    { id: 10749, name: "Romance", hrName: "Romantika" },
    { id: 878, name: "Science Fiction", hrName: "Naučna fantastika" },
    { id: 10770, name: "TV Movie", hrName: "TV film" },
    { id: 53, name: "Thriller", hrName: "Triler" },
    { id: 10752, name: "War", hrName: "Ratni" },
    { id: 37, name: "Western", hrName: "Vestern" }
  ];

  const sortOptions = [
    { id: 'random', label: 'Nasumično' },
    { id: 'rating', label: 'Po ocjeni' },
    { id: 'popularity', label: 'Po popularnosti' },
    { id: 'release_date', label: 'Po datumu izlaska' }
  ];

  // Translate sortMode to Croatian
  const translateSortMode = (mode) => {
    const translations = {
      random: 'Nasumično',
      rating: 'Po ocjeni',
      popularity: 'Po popularnosti',
      release_date: 'Po datumu izlaska'
    };
    return translations[mode] || mode;
  };

  const translateGenre = (genre) => {
    const genreMapping = genres.find((g) => g.name === genre);
    return genreMapping ? genreMapping.hrName : genre ? genre : "Svi žanrovi";
  };

  const handleKeyDown = useCallback((e) => {
    if (!isNavBarFocused) return;
    e.stopPropagation();
    switch (e.key) {
      case 'ArrowRight':
        e.preventDefault();
        setFocusedIndex((prevIndex) => (prevIndex < buttonsRef.current.length - 1 ? prevIndex + 1 : 0));
        break;
      case 'ArrowLeft':
        e.preventDefault();
        setFocusedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : buttonsRef.current.length - 1));
        break;
      case 'ArrowDown':
        e.preventDefault();
        const firstMainVodItem = document.querySelector('[data-mainvod="true"]');
        if (firstMainVodItem) {
          firstMainVodItem.focus();
        }
        break;
      case 'Enter':
      case 'OK':
        e.preventDefault();
        const currentButton = buttonsRef.current[focusedIndex];
        if (currentButton) {
          const onClick = currentButton.onclick;
          if (onClick) {
            onClick();
          } else {
            currentButton.click();
          }
        }
        break;
      default:
        break;
    }
  }, [focusedIndex, isNavBarFocused]);

  const handleGenreKeyDown = (e) => {
    e.stopPropagation();
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setFocusedGenreIndex((prevIndex) => (prevIndex < genres.length - 1 ? prevIndex + 1 : 0));
        break;
      case 'ArrowUp':
        e.preventDefault();
        setFocusedGenreIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : genres.length - 1));
        break;
      case 'Enter':
        e.preventDefault();
        handleGenreSelect(genres[focusedGenreIndex].name);
        break;
      case 'Escape':
        e.preventDefault();
        setIsModalOpen(false);
        break;
      default:
        break;
    }
  };

  const handleSortKeyDown = (e) => {
    e.stopPropagation();
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setFocusedSortIndex((prevIndex) => (prevIndex < sortOptions.length - 1 ? prevIndex + 1 : 0));
        break;
      case 'ArrowUp':
        e.preventDefault();
        setFocusedSortIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : sortOptions.length - 1));
        break;
      case 'Enter':
        e.preventDefault();
        handleSortSelect(sortOptions[focusedSortIndex].id);
        break;
      case 'Escape':
        e.preventDefault();
        setIsSortModalOpen(false);
        break;
      default:
        break;
    }
  };

  const handleGenreSelect = (genre) => {
    onGenreChange(genre);
    setIsModalOpen(false);
  };

  const handleSortSelect = (sortOption) => {
    onSortToggle(sortOption);
    setIsSortModalOpen(false);
  };

  const handleFocus = () => setIsNavBarFocused(true);
  const handleBlur = () => setIsNavBarFocused(false);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    if (buttonsRef.current[focusedIndex]) {
      buttonsRef.current[focusedIndex].focus();
    }
  }, [focusedIndex]);

  useEffect(() => {
    if (isModalOpen && genreListRef.current[focusedGenreIndex]) {
      genreListRef.current[focusedGenreIndex].focus();
    }
  }, [isModalOpen, focusedGenreIndex]);

  useEffect(() => {
    if (isSortModalOpen && sortListRef.current[focusedSortIndex]) {
      sortListRef.current[focusedSortIndex].focus();
    }
  }, [isSortModalOpen, focusedSortIndex]);

  if (location.pathname === '/login') {
    return null;
  }

  const navigateTo = (path) => {
    if (location.pathname !== path) {
      history.push(path);
    }
  };

  return (
    <Nav>
      <TopRow>
        <ContainerButton>
          <Button
            to={`menu/`}
            aria-label="Open menu"
            ref={(el) => (buttonsRef.current[0] = el)}
            tabIndex="0"
            data-navbar="true"
            onFocus={handleFocus}
            onBlur={handleBlur}
          >
            <FaEllipsisV />
          </Button>
        </ContainerButton>
        <LogoLink
          to="/"
          aria-label="Home"
          ref={(el) => (buttonsRef.current[1] = el)}
          tabIndex="0"
          data-navbar="true"
          onFocus={handleFocus}
          onBlur={handleBlur}
        >
          <FaHome />
        </LogoLink>
        <ContainerButton>
          {auth.isAuth() && !location.pathname.includes('menu') && (
            <>
              <Button
                to={{ pathname: `${location.pathname.split('?')[0]}search/`, search: window.location.search }}
                aria-label="Search"
                ref={(el) => (buttonsRef.current[2] = el)}
                tabIndex="0"
                data-navbar="true"
                onFocus={handleFocus}
                onBlur={handleBlur}
              >
                <FaSearch />
              </Button>
              <Button
                to={{
                  pathname: `/${location.pathname.includes('series') ? 'series' : 'movie'}/category/`,
                  search: window.location.search
                }}
                aria-label="Select category"
                ref={(el) => (buttonsRef.current[3] = el)}
                tabIndex="0"
                data-navbar="true"
                onFocus={handleFocus}
                onBlur={handleBlur}
              >
                <FaGripLines />
              </Button>
              <StyledButton
                onClick={() => setIsSortModalOpen(true)}
                aria-label="Sortiraj"
                ref={(el) => (buttonsRef.current[4] = el)}
                tabIndex="0"
                data-navbar="true"
                onFocus={handleFocus}
                onBlur={handleBlur}
              >
                Sortiraj
              </StyledButton>
              <StyledButton
                onClick={() => setIsModalOpen(true)}
                aria-label="Select genre"
                ref={(el) => (buttonsRef.current[5] = el)}
                tabIndex="0"
                data-navbar="true"
                onFocus={handleFocus}
                onBlur={handleBlur}
              >
                Žanrovi
              </StyledButton>
              <StyledButton
                onClick={() => navigateTo(`/${location.pathname.includes('series') ? 'series' : 'movie'}/category/fav/`)}
                aria-label="Favoriti"
                ref={(el) => (buttonsRef.current[6] = el)}
                tabIndex="0"
                data-navbar="true"
                onFocus={handleFocus}
                onBlur={handleBlur}
              >
                Favoriti
              </StyledButton>
            </>
          )}
        </ContainerButton>
      </TopRow>

      {/* Separator between TopRow and FilterInfo */}
      {showFilterInfo && <Separator />}

      {/* Display FilterInfo only if showFilterInfo is true */}
      {showFilterInfo && (
        <FilterInfo>
          <LabelSpan>Sortirano po:</LabelSpan> <ValueSpan>{translateSortMode(sortMode)}</ValueSpan> | <LabelSpan>Žanr:</LabelSpan> <ValueSpan>{selectedGenre ? translateGenre(selectedGenre) : "Svi žanrovi"}</ValueSpan> | <LabelSpan>Kategorija:</LabelSpan> <ValueSpan>{currentCategory ? currentCategory : "N/A"}</ValueSpan>
        </FilterInfo>
      )}

      {/* Genre Modal */}
      <Modal isOpen={isModalOpen}>
        <List>
          {genres.map((genre, index) => (
            <ListItem
              key={genre.id}
              tabIndex="0"
              ref={(el) => (genreListRef.current[index] = el)}
              onClick={() => handleGenreSelect(genre.name)}
              onKeyDown={handleGenreKeyDown}
            >
              {genre.hrName}
            </ListItem>
          ))}
        </List>
      </Modal>

      {/* Sort Modal */}
      <Modal isOpen={isSortModalOpen}>
        <List>
          {sortOptions.map((option, index) => (
            <ListItem
              key={option.id}
              tabIndex="0"
              ref={(el) => (sortListRef.current[index] = el)}
              onClick={() => handleSortSelect(option.id)}
              onKeyDown={handleSortKeyDown}
            >
              {option.label}
            </ListItem>
          ))}
        </List>
      </Modal>
    </Nav>
  );
};

// Adding PropTypes for safety
NavBar.propTypes = {
  onSortToggle: PropTypes.func.isRequired,
  sortMode: PropTypes.string.isRequired,
  onGenreChange: PropTypes.func.isRequired,
  selectedGenre: PropTypes.string,
  currentCategory: PropTypes.string,
  showFilterInfo: PropTypes.bool.isRequired,
};

export default NavBar;