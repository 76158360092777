// src/components/Series/ErrorBoundary.js
import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Ažurirajte stanje kako bi se prikazao fallback UI
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Možete logirati grešku u servise za logiranje grešaka
    console.error("ErrorBoundary caught an error", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // Fallback UI kada dođe do greške
      return (
        <div style={{ padding: '2rem', textAlign: 'center', color: 'white', background: 'black' }}>
          <h2>Nešto je pošlo po zlu.</h2>
          <p>Pokušajte ponovo kasnije ili kontaktirajte podršku.</p>
        </div>
      );
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;