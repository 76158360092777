// src/index.js

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { register, unregister } from './serviceWorkerRegistration';
import { HashRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import allReducers from './reducer';
import LandscapeWarning from './components/LandscapeWarning';
import isSmartTV from './utils/isSmartTV';
import { ThemeProvider } from 'styled-components';
import { theme } from './theme';

const store = createStore(
  allReducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.warn = () => {};
  console.error = () => {};
}

if (isSmartTV()) {
  window.addEventListener('load', () => {
    document.body.style.cursor = 'none';
  });
}

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Router>
          <App />
          <LandscapeWarning />
        </Router>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// Registracija Servisnog Radnika samo u produkciji
if (process.env.NODE_ENV === 'production') {
  register();
} else {
  unregister();
}