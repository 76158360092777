// src/theme.js
export const theme = {
  colors: {
    primary: '#1c1c1c',
    secondary: '#e50914', // Boja koja se koristi u NavBar.js i RowItem.js
    accent: '#46d369',
    background: '#141414',
    text: '#ffffff',
    // Dodajte ostale boje prema potrebi
  },
  spacing: {
    xs: '0.25rem',
    sm: '0.5rem',
    md: '1rem',
    lg: '2rem',
    xl: '3rem',
  },
  breakpoints: {
    sm: '576px',
    md: '768px',
    lg: '1200px',
  },
};