import styled from "styled-components";
import React, { useEffect, useState, useRef } from 'react';
import { getVodInfo, getSeriesInfo } from "../../other/load-playlist";
import { optimizeName } from "../../other/vod-series-name-optimizer";
import { useParams, Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Seasons from "../Series/Seasons";
import DB from "../../other/local-db";

// **Styled Components**
const Container = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  user-select: none;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  overflow-y: auto;
`;

const Box = styled.div`
  position: relative;
  max-width: 800px;
  margin: 5vh auto;
  background-color: #181818;
  border-radius: 0.4rem;
  overflow: hidden;
  @media (max-width: 768px) {
    width: 90vw;
    margin: 2vh auto;
  }
  @media (max-width: 576px) {
    width: 95vw;
    margin: 1vh auto;
  }
`;

const Header = styled.div`
  position: relative;
  height: 50vh;
  background-color: #000;
  overflow: hidden;
  @media (max-width: 768px) {
    height: 40vh;
  }
  @media (max-width: 576px) {
    height: 30vh;
  }
`;

const GradientOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7) 10%, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0.7) 90%);
  z-index: 1;
`;

const ImgHovered = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.5s ease-in-out;
  opacity: ${({ isLoaded }) => (isLoaded ? 1 : 0)};
  z-index: 0;
`;

const HeaderContent = styled.div`
  position: absolute;
  bottom: 15%;
  left: 5%;
  color: white;
  z-index: 2;
  max-width: 60%;
`;

const ButtonRow = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;
`;

const ButtonMain = styled(Link)`
  display: flex;
  align-items: center;
  background-color: white;
  color: black !important;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  font-weight: bold;
  margin-right: 1rem;
  text-decoration: none;
  font-size: 1rem;
  &:hover {
    background-color: rgba(255,255,255,0.75);
  }
  i {
    margin-right: 0.5rem;
  }
`;

const ButtonSecondary = styled.button`
  display: flex;
  align-items: center;
  background-color: rgba(109, 109, 110, 0.7);
  color: white;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  &:hover {
    background-color: rgba(109, 109, 110, 0.5);
  }
  i {
    margin-right: 0.5rem;
  }
`;

const Body = styled.div`
  position: relative;
  background-color: #181818;
  color: white;
  padding: 1rem 4%;
  @media (max-width: 768px) {
    padding: 0.5rem;
  }
  z-index: 1;
`;

const RatingBadge = styled.span`
  background-color: #46d369;
  color: black;
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
  font-weight: bold;
  margin-right: 0.5rem;
`;

const YearBadge = styled.span`
  background-color: #f2e205;
  color: black;
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
  font-weight: bold;
  margin-right: 0.5rem;
`;

const DurationBadge = styled.span`
  background-color: #555;
  color: white;
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
  font-weight: bold;
`;

const DescriptionDiv = styled.div`
  margin-top: 1rem;
  font-size: 1.2rem;
  line-height: 1.6;
  cursor: pointer;
  -webkit-line-clamp: ${({ expand }) => (expand ? 'unset' : 3)};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const TitleInfo = styled.span`
  color: #777;
  margin-right: 0.2rem;
  font-size: 1.2rem;
`;

const TitleData = styled.span`
  color: #ddd;
  font-size: 1.2rem;
`;

const CloseBtn = styled.i`
  position: absolute;
  top: 1rem;
  right: 1.5rem;
  color: white;
  cursor: pointer;
  z-index: 100;
  font-size: 2rem;
  text-align: right;
  &:hover {
    color: rgba(255,255,255,0.75);
  }
`;

const AdditionalInfo = styled.div`
  margin-top: 1rem;
  font-size: 1.2rem;
`;

// **MoreInfo Component**
const MoreInfo = ({ style }) => {
  const { category, stream_id, playingMode } = useParams();
  const history = useHistory();
  const stream = useSelector((state) => state.playlist).find((x) =>
    playingMode === "series"
      ? parseInt(x.series_id) === parseInt(stream_id)
      : parseInt(x.stream_id) === parseInt(stream_id)
  );

  const [expandDescription, setExpandDescription] = useState(false);
  const [Name, setName] = useState(stream ? optimizeName(stream.name) : '');
  const [Description, setDescription] = useState(stream && stream.overview ? stream.overview : "");
  const [ImageSrc, setImageSrc] = useState(stream && stream.stream_icon);
  const [Rating, setRating] = useState(stream && stream.rating ? parseFloat(stream.rating).toFixed(1) : null);
  const [Duration, setDuration] = useState(null);
  const [backdropPath, setBackdropPath] = useState(null);
  const [Genres, setGenres] = useState(stream && stream.genres ? stream.genres.join(', ') : "");
  const [Actor, setActor] = useState(
    Array.isArray(stream?.cast) ? stream.cast.join(', ') : stream?.cast || ""
  );
  const [Year, setYear] = useState(stream && stream.release_date ? stream.release_date.slice(0, 4) : "");
  const [seasons, setSeasons] = useState([]);
  const [favorite, setFavorite] = useState(null);

  const modalRef = useRef(null);
  const [isBackdropLoaded, setIsBackdropLoaded] = useState(false);

  // Ref to track if component is mounted
  const isMounted = useRef(true);

  useEffect(() => {
    // Set isMounted to true when component mounts
    isMounted.current = true;
    setFavorite(!!DB.findOne(playingMode, stream_id, true));

    async function fetchData() {
      if (!stream) {
        if (isMounted.current) {
          history.replace(`/${playingMode}/`);
        }
        return;
      }

      // If description is already set from 'overview', skip setting it from API
      if (Description && Description.length > 0) {
        // Skip fetching description
      } else {
        const result =
          playingMode === "series"
            ? await getSeriesInfo(stream.series_id, stream.name, false, stream.tmdb)
            : await getVodInfo(stream.stream_id, stream.name, stream.tmdb);
        if (isMounted.current && result && result.info) {
          let info = result.info;
          info.name && setName(info.name);
          info.description && setDescription(info.description);
          info.image && setImageSrc(info.image);
          info.cover && setImageSrc(info.cover);
          info.backdrop_path &&
            info.backdrop_path.length > 0 &&
            setBackdropPath(info.backdrop_path[0]);
          info.genre && setGenres(info.genre);
          info.actors &&
            setActor(Array.isArray(info.actors) ? info.actors.join(", ") : info.actors);
          if (info.releasedate) {
            const date = new Date(info.releasedate);
            if (!isNaN(date)) {
              const day = ("0" + date.getDate()).slice(-2);
              const month = ("0" + (date.getMonth() + 1)).slice(-2);
              const year = date.getFullYear();
              setYear(`${day}.${month}.${year}`);
            } else {
              setYear(info.releasedate);
            }
          }
          if (info.rating && !Rating) {
            const ratingValue = parseFloat(info.rating).toFixed(1);
            setRating(ratingValue);
          }
          if (info.duration || info.runtime) {
            const runtime = info.duration || info.runtime;
            setDuration(runtime);
          }
          playingMode === "series" && setSeasons(result.episodes);
        }
      }
    }
    fetchData();
    // Cleanup function to set isMounted to false when component unmounts
    return () => {
      isMounted.current = false;
    };
  }, [playingMode, stream, stream_id, history, Description, Rating]);

  // Preload backdrop image
  useEffect(() => {
    let isImageMounted = true;
    if (backdropPath || ImageSrc) {
      const img = new Image();
      img.src = backdropPath || ImageSrc;
      img.onload = () => {
        if (isImageMounted) {
          setIsBackdropLoaded(true);
        }
      };
      img.onerror = () => {
        if (isImageMounted) {
          setIsBackdropLoaded(false);
        }
      };
    }
    return () => {
      isImageMounted = false;
    };
  }, [backdropPath, ImageSrc]);

  // Handle keyboard navigation
  useEffect(() => {
    const handleKeyDown = (event) => {
      const focusableElements = modalRef.current.querySelectorAll(
        '.play-button, .favorite-button, .close-button'
      );
      const currentIndex = Array.from(focusableElements).indexOf(document.activeElement);
      switch (event.key) {
        case 'ArrowDown':
          event.preventDefault();
          const nextIndex = (currentIndex + 1) % focusableElements.length;
          focusableElements[nextIndex].focus();
          break;
        case 'ArrowUp':
          event.preventDefault();
          const prevIndex =
            (currentIndex - 1 + focusableElements.length) % focusableElements.length;
          focusableElements[prevIndex].focus();
          break;
        case 'ArrowLeft':
        case 'ArrowRight':
          event.preventDefault();
          break;
        case 'Escape':
        case 'Backspace':
          handleClose();
          break;
        case 'Enter':
          event.preventDefault();
          if (document.activeElement.classList.contains('favorite-button')) {
            setFavoriteGlob(event);
          } else if (document.activeElement.classList.contains('play-button')) {
            document.activeElement.click();
          }
          break;
        default:
          break;
      }
    };

    const modalNode = modalRef.current;
    modalNode.addEventListener('keydown', handleKeyDown);
    if (playingMode !== "series") {
      const playButton = modalNode.querySelector('.play-button');
      if (playButton) {
        playButton.focus();
      }
    } else {
      const favoriteButton = modalNode.querySelector('.favorite-button');
      if (favoriteButton) {
        favoriteButton.focus();
      }
    }
    return () => {
      modalNode.removeEventListener('keydown', handleKeyDown);
    };
  }, [favorite, playingMode]);

  const setFavoriteGlob = (event) => {
    event.stopPropagation();
    if (favorite) {
      DB.del(playingMode, stream_id, true);
    } else {
      DB.set(playingMode, stream_id, { id: stream_id }, true);
    }
    setFavorite(!favorite);
  };

  const handleClose = () => {
    document.body.style.filter = "none";
    // Close the modal without refreshing or navigating away
    history.goBack();
  };

  return (
    <Container style={style} ref={modalRef} tabIndex="0">
      <Box>
        {!window.gSTB && (
          <CloseBtn
            className="fas fa-times close-button"
            onClick={handleClose}
            tabIndex="0"
            aria-label="Close"
          ></CloseBtn>
        )}
        <Header>
          <ImgHovered
            src={backdropPath || ImageSrc}
            alt={`${Name} backdrop`}
            isLoaded={isBackdropLoaded}
            onError={() => setIsBackdropLoaded(false)}
          />
          <GradientOverlay />
          <HeaderContent>
            <h1>{Name}</h1>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '0.5rem' }}>
              {Rating && <RatingBadge>{Rating}</RatingBadge>}
              {Year && <YearBadge>{Year}</YearBadge>}
              {Duration && <DurationBadge>{Duration}</DurationBadge>}
            </div>
            <ButtonRow>
              {playingMode !== "series" && (
                <ButtonMain
                  className="play-button"
                  to={{
                    pathname: `/movie/category/${category}/${stream_id}/play/`,
                    search: window.location.search,
                  }}
                  tabIndex="0"
                >
                  <i className="fas fa-play"></i> Pokreni
                </ButtonMain>
              )}
              <ButtonSecondary
                className="favorite-button"
                onClick={setFavoriteGlob}
                tabIndex="0"
              >
                <i className={favorite !== false ? "fas fa-check" : "fas fa-plus"}></i> Dodaj u favorite
              </ButtonSecondary>
            </ButtonRow>
          </HeaderContent>
        </Header>
        <Body>
          <DescriptionDiv
            expand={expandDescription}
            onClick={() => setExpandDescription(!expandDescription)}
          >
            {Description}
          </DescriptionDiv>
          <AdditionalInfo>
            {Actor && (
              <>
                <TitleInfo>Glumci: </TitleInfo>
                <TitleData>{Actor}</TitleData>
                <br />
              </>
            )}
            {Genres && (
              <>
                <TitleInfo>Žanrovi: </TitleInfo>
                <TitleData>{Genres}</TitleData>
                <br />
              </>
            )}
          </AdditionalInfo>
          {playingMode === "series" && (
            <Seasons seasonData={seasons} cover={backdropPath || ImageSrc} />
          )}
        </Body>
      </Box>
    </Container>
  );
};

export default MoreInfo;