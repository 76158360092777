// src/components/Vod/RowItem.js
import React, { useState, useEffect, forwardRef, useRef } from 'react';
import styled, { css } from 'styled-components';
import { optimizeName } from "../../other/vod-series-name-optimizer";
import { Link } from "react-router-dom";
import DB from "../../other/local-db";
import PropTypes from 'prop-types';

// Zajednički stil za badge
const badgeStyles = css`
  position: absolute;
  top: 8px; /* Postavljanje na vrh */
  padding: 0.3rem 0.6rem;
  border-radius: 0.3rem;
  font-weight: bold;
  font-size: 0.8rem;
  z-index: 2;
  opacity: 0.9;
`;

// Gradijentne pozadine za značke
const RatingBadge = styled.div`
  ${badgeStyles}
  right: 8px;
  background: linear-gradient(45deg, #46d369, #32a852);
  color: black;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
`;

// YearBadge je sada u gornjem lijevom kutu sa fiksnom bojom
const YearBadge = styled.div`
  ${badgeStyles}
  left: 8px;
  background-color: #f2e205; /* Postavljena fiksna boja */
  color: black;
`;

// Stilizirani Link (Li) sa fleksibilnim rasporedom i margin-bottom za razmak između redova
const Li = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  height: auto; /* Uklanja fiksnu visinu */
  text-align: center;
  padding: 0;
  cursor: pointer;
  outline: none;
  text-decoration: none !important;
  position: relative;
  z-index: 1;
  overflow: hidden;
  border-radius: 0.5rem; /* Dodan border-radius */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: 1.5rem; /* Povećan razmak između redova */

  &:hover,
  &:focus {
    transform: scale(1.09);
    box-shadow: rgba(0, 0, 0, 0.5) 0px 10px 20px;
    z-index: 10;

    & > div.img-container {
      transform: scale(1.03);
    }

    /* Pravilno targetiranje InfoContainer-a unutar img-container */
    .info-container {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &:focus {
    outline: 2px solid ${({ theme }) => theme.colors.secondary || '#e50914'};
    outline-offset: 2px;
  }

  @media (max-width: 768px) {
    /* Visina se automatski prilagođava zbog flex rasporeda */
  }

  @media (max-width: 576px) {
    /* Visina se automatski prilagođava zbog flex rasporeda */
  }
`;

// Stilizirani ImgContainer
const ImgContainer = styled.div`
  width: 100%;
  padding-top: 150%; /* Održava omjer slike (2:3) */
  position: relative;
  overflow: hidden;
  border-radius: 0.5rem; /* Dodan border-radius */
  background-color: #333; /* Fallback boja */
  transition: transform 0.3s ease;
`;

// Stilizirani ImgHovered koristeći <img> tag
const ImgHovered = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.5s ease-in-out;
  opacity: ${({ isLoaded }) => (isLoaded ? 1 : 0)};
  z-index: 0;
`;

// Stilizirani Title sa fleksibilnim rastom i smanjenjem fonta na manjim ekranima
const Title = styled.div`
  flex-grow: 0; /* Naslov ne raste */
  background: rgba(0, 0, 0, 0.7);
  color: ${({ theme }) => theme.colors.text || '#fff'};
  padding: 0.5rem;
  font-size: 1rem;
  text-align: center;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: 768px) {
    font-size: 0.9rem; /* Smanjuje font za manje ekrane */
  }
`;

// Stilizirani InfoContainer
const InfoContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.85);
  color: #fff;
  padding: 1rem;
  font-size: 0.9rem;
  text-align: left;
  transform: translateY(100%);
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
  max-height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

// Stilizirani Description
const Description = styled.div`
  font-size: 1rem;
  color: #ccc;
  margin-bottom: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6; /* Ograničava na 6 linija */
  -webkit-box-orient: vertical;
`;

// Stilizirani Genres
const Genres = styled.div`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.accent || '#46d369'}; /* Postavlja boju teksta na zelenu */
  margin-bottom: 0.5rem;
`;

// Stilizirani CastList
const CastList = styled.div`
  position: absolute;
  bottom: 5px;
  left: 3px;
  right: 3px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 0.2rem 0.1rem;
  border-radius: 0.3rem;
  font-size: 1rem;
  text-align: center;
  z-index: 2;
  max-height: 5rem;
  overflow: hidden;
  text-overflow: ellipsis;

  /* Skriva CastList kada je InfoContainer vidljiv */
  ${Li}:hover &,
  ${Li}:focus & {
    display: none;
  }
`;

// Stilizirani ProgressBar
const ProgressBar = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 6px;
  background-color: #333;
  border-radius: 0 0 0.5rem 0.5rem;
  overflow: hidden;

  & > div {
    height: 100%;
    background-color: ${({ theme }) => theme.colors.secondary || '#e50914'};
    width: ${({ width }) => width}%;
    transition: width 0.3s ease;
  }
`;

// Stilizirani NoCoverStyle
const NoCoverStyle = styled.div`
  background-color: #333;
  color: ${({ theme }) => theme.colors.text || '#fff'};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: 0.5rem;
  padding: 1rem;
  text-align: center;
`;

// RowItem komponenta
const RowItem = forwardRef(({
  name,
  stream_icon,
  stream_id,
  category_id,
  id,
  style,
  isSeries,
  container_extension,
  existingTmdb,
  rowIndex,
  overview,
  rating,
  year,
  genres,
  cast = []
}, ref) => {
  const [noCover, setNoCover] = useState(!stream_icon);
  const [streamStat, setStreamStat] = useState();
  const [showInfo, setShowInfo] = useState(false);
  const itemRef = useRef();
  const [isLoaded, setIsLoaded] = useState(false); // Dodano state za lazy loading

  useEffect(() => {
    setStreamStat(DB.findOne(isSeries ? "series" : "movie", stream_id, category_id === "fav"));
  }, [isSeries, stream_id, category_id]);

  useEffect(() => {
    setNoCover(!stream_icon);
  }, [stream_icon]);

  const handleFocus = () => {
    setShowInfo(true);

    if (itemRef.current) {
      setTimeout(() => {
        itemRef.current.scrollIntoView({
          behavior: 'smoth',
          block: 'center',
          inline: 'nearest',
        });
      }, 50);
    }
  };

  // Dodatna provjera da li je cast niz
  const safeCast = Array.isArray(cast) ? cast : [];

  return (
    <Li
      ref={itemRef}
      className="vod-item"
      data-id={id}
      style={style}
      onMouseEnter={() => setShowInfo(true)}
      onMouseLeave={() => setShowInfo(false)}
      onFocus={handleFocus}
      onBlur={() => setShowInfo(false)}
      to={`/${isSeries ? "series" : "movie"}/category/${category_id}/${stream_id}/info/`}
      tabIndex={0}
      data-mainvod="true"
      onKeyDown={(e) => {
        e.stopPropagation();
        const focusableElements = Array.from(document.querySelectorAll('[tabindex="0"]'));
        const currentIndex = focusableElements.indexOf(document.activeElement);
        const itemsPerRow = 6; // Pet RowItem-a + 1 ShowMoreButton

        if (e.key === 'ArrowUp') {
          e.preventDefault();
          if (rowIndex === 0) {
            const lastNavBarItem = document.querySelector('[data-navbar="true"]');
            if (lastNavBarItem) {
              lastNavBarItem.focus();
            }
          } else {
            if (currentIndex - itemsPerRow >= 0) {
              focusableElements[currentIndex - itemsPerRow].focus();
            }
          }
        } else if (e.key === 'ArrowDown') {
          e.preventDefault();
          if (currentIndex + itemsPerRow < focusableElements.length) {
            focusableElements[currentIndex + itemsPerRow].focus();
          }
        } else if (e.key === 'ArrowLeft') {
          e.preventDefault();
          if (currentIndex > 0) {
            focusableElements[currentIndex - 1].focus();
          }
        } else if (e.key === 'ArrowRight') {
          e.preventDefault();
          if (currentIndex < focusableElements.length - 1) {
            focusableElements[currentIndex + 1].focus();
          }
        } else if (e.key === 'Enter' || e.key === 'OK') {
          e.preventDefault();
          document.activeElement.click();
        }
      }}
    >
      <ImgContainer className="img-container">
        {!noCover ? (
          <>
            <ImgHovered
              src={stream_icon}
              alt={name}
              loading="lazy" // Dodan lazy loading
              isLoaded={isLoaded}
              onLoad={() => setIsLoaded(true)}
              onError={() => setNoCover(true)}
            />
            {rating && <RatingBadge>{parseFloat(rating).toFixed(1)}</RatingBadge>}
            {year && <YearBadge>{year}</YearBadge>}
            <CastList>{safeCast.slice(0, 3).join(', ')}</CastList>
            <InfoContainer className="info-container">
              {overview && <Description>{overview}</Description>}
              {genres && genres.length > 0 && <Genres>Žanrovi: {genres.join(', ')}</Genres>}
            </InfoContainer>
          </>
        ) : (
          <NoCoverStyle>
            <Title>{optimizeName(name)}</Title>
          </NoCoverStyle>
        )}
      </ImgContainer>
      {/* Naslov je sada deo fleksibilnog rasporeda */}
      {!noCover && <Title>{optimizeName(name)}</Title>}
      {streamStat && streamStat.tot > 3 && streamStat.tot < 95 && (
        <ProgressBar width={streamStat.tot}>
          <div />
        </ProgressBar>
      )}
    </Li>
  );
});

// Dodavanje PropTypes za sigurnost
RowItem.propTypes = {
  name: PropTypes.string.isRequired,
  stream_icon: PropTypes.string,
  stream_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  category_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  id: PropTypes.number.isRequired,
  style: PropTypes.object,
  isSeries: PropTypes.bool.isRequired,
  container_extension: PropTypes.string,
  existingTmdb: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  overview: PropTypes.string,
  rating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  year: PropTypes.string,
  genres: PropTypes.arrayOf(PropTypes.string),
  cast: PropTypes.arrayOf(PropTypes.string), // Ostaviti kao array
};

export default React.memo(RowItem);