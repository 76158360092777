// src/components/Vod/MainVod.js

import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { setPlaylist } from "../../actions/set-Playlist";
import { setGroupList } from "../../actions/set-Group";
import { loadGroup, loadPlaylist } from "../../other/load-playlist";
import { useParams, useHistory, Route, Switch, useLocation } from "react-router-dom";
import BackgroundPlayer from "./BackgroundPlayer";
import GroupRow from "./GroupRow";
import MoreInfo from "./MoreInfo";
import PlayerSeries from "../Series/PlayerSeries";
import RowItem from "./RowItem";
import PlayerVod from "./PlayerVod";
import NavBar from "../NavBar";
import { VariableSizeList as List, VariableSizeGrid as Grid } from "react-window";
import { convertRemToPixels, convertVhToPixels } from "../../other/convert-rem";
import Popup from "../Popup/Popup";
import DB from "../../other/local-db";
import { Loading } from "../../other/Player-github/style";
import axios from "axios";

const Container = styled.div`
  height: 100vh;
  overflow: auto;
  max-width: 100%;
  overflow-x: hidden;
  transition: filter 0.5s ease;
  background-color: var(--first-color);
  margin-top: ${convertRemToPixels(4)}px;

  @media (max-width: 768px) {
    margin-top: ${convertRemToPixels(3)}px;
  }

  @media (max-width: 576px) {
    margin-top: ${convertRemToPixels(2)}px;
  }
`;

const Spin = styled.div`
  height: 100vh;
  overflow: auto;
  max-width: 100%;
  width: 100vw;
  overflow-x: hidden;
  background-color: transparent;
  position: absolute;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const GroupRowContainer = styled.ul`
  padding-top: 4rem;
  padding-bottom: 2rem;
  background-color: var(--first-color);
  list-style-type: none;
  padding-left: 1.7rem;
  max-width: 100vw;
  overflow: visible;
  z-index: 1;

  @media (max-width: 768px) {
    padding-left: 1rem;
  }

  @media (max-width: 576px) {
    padding-left: 0.5rem;
  }
`;

// Helper functions
const getOtherCategoryName = (cat, playingMode) => {
  if (cat === "fav") return "Favoriti";
  if (cat === "toend") return "Nastavi gledati";
  if (cat === "allmovies") return "Svi Filmovi";
  if (cat === "allseries") return "Sve Serije";
  return "";
};

function shuffle(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

const removeDuplicateMovies = (movies) => {
  const seenTitles = new Set();
  return movies.filter((movie) => {
    const title = movie.name.trim().toLowerCase();
    if (seenTitles.has(title)) {
      return false;
    } else {
      seenTitles.add(title);
      return true;
    }
  });
};

const parseArray = (data) => {
  if (Array.isArray(data)) {
    return data;
  } else if (typeof data === "string") {
    return data.split(",").map((item) => item.trim());
  } else {
    return [];
  }
};

// Function to select a random movie
function searchRandomMovie(playlist) {
  const filteredPlaylist = playlist.filter((x) => !x.name.match(/xxx/i)); // Filter out unwanted content
  const highRatedMovies = filteredPlaylist.filter((x) => x.rating >= 7);
  if (highRatedMovies.length > 0) {
    return { ...highRatedMovies[Math.floor(Math.random() * highRatedMovies.length)] };
  } else {
    return { ...filteredPlaylist[Math.floor(Math.random() * filteredPlaylist.length)] };
  }
}

const MainVod = () => {
  const { category: routeCategory, playingMode } = useParams();
  const [category, setCategory] = useState(routeCategory);
  const [streamList, setStreamList] = useState([]);
  const [playlistState, setPlaylistState] = useState([]);
  const [sortedPlaylist, setSortedPlaylist] = useState([]);
  const [allItems, setAllItems] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const categories = useSelector((state) => state.groupsList);
  const [blurBackground, setBlurBackground] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isDataReady, setIsDataReady] = useState(false);

  const [lastFocusedItem, setLastFocusedItem] = useState(null); // New state for last focused item

  // Get saved sort state from localStorage
  const savedSortMode = localStorage.getItem("currentSortMode") || "popularity";
  const [sortMode, setSortMode] = useState(savedSortMode);

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const searchText = query.get("search") || ""; // Read searchText from URL
  const querySortMode = query.get("sort") || sortMode;
  const queryGenre = query.get("genre") || "";

  // Initialize selectedGenre with queryGenre
  const [selectedGenre, setSelectedGenre] = useState(queryGenre);

  const firstRowItemRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // State for showing FilterInfo
  const [showFilterInfo, setShowFilterInfo] = useState(false);

  const showBlur = useCallback(
    (mode) => {
      setBlurBackground(mode !== 0 ? { filter: "blur(.5rem)", pointerEvents: "none" } : {});
      if (mode === 0) setIsLoading(false);
    },
    []
  );

  const getGroupRowPlaylist = useCallback(
    (index) => {
      const categoryItem = categories[index - 1];
      if (!categoryItem) return [];

      if (
        (playingMode === "movie" && categoryItem.category_id === "allmovies") ||
        (playingMode === "series" && categoryItem.category_id === "allseries")
      ) {
        return sortedPlaylist;
      }

      if (categoryItem.favorite === 1) {
        return sortedPlaylist.filter((s) => {
          const favoriteItem = DB.findOne(
            playingMode,
            playingMode === "series" ? s.series_id : s.stream_id,
            true
          );
          return favoriteItem?.isFavorite;
        });
      }
      if (categoryItem.history === 1) {
        return sortedPlaylist.filter((s) => {
          const historyItem = DB.findOne(
            playingMode,
            playingMode === "series" ? s.series_id : s.stream_id,
            false
          );
          return historyItem && historyItem.tot > 3 && historyItem.tot < 95;
        });
      }
      return sortedPlaylist.filter(
        (s) => parseInt(s.category_id) === parseInt(categoryItem.category_id)
      );
    },
    [categories, sortedPlaylist, playingMode]
  );

  useEffect(() => {
    if (!location.pathname.includes('/info/') && !location.pathname.includes('/play/')) {
      setCategory(routeCategory);
    }
  }, [routeCategory, location.pathname]);

  useEffect(() => {
    if (isModalOpen) return;
    const applySorting = (moviesToSort) => {
      let sortedMovies = [];
      switch (sortMode) {
        case "rating":
          sortedMovies = sortMoviesByRating(moviesToSort);
          break;
        case "release_date":
          sortedMovies = sortMoviesByReleaseDate(moviesToSort);
          break;
        case "random":
          sortedMovies = [...moviesToSort];
          shuffle(sortedMovies);
          break;
        case "popularity":
        default:
          sortedMovies = sortMoviesByPopularity(moviesToSort);
          break;
      }
      // After sorting, move items without cover images to the end
      const moviesWithCover = sortedMovies.filter((movie) => movie.stream_icon || movie.cover);
      const moviesWithoutCover = sortedMovies.filter((movie) => !movie.stream_icon && !movie.cover);
      const finalSortedMovies = [...moviesWithCover, ...moviesWithoutCover];
      setSortedPlaylist(finalSortedMovies);
      setIsDataReady(true); // Data is ready after sorting is applied
    };
    applySorting(playlistState);
    localStorage.setItem("currentSortMode", sortMode);
  }, [sortMode, playlistState, isModalOpen]);

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      setIsDataReady(false); // Set data ready to false before fetching
      showBlur(1);
      // Reset the state variables
      setStreamList([]);
      setPlaylistState([]);
      setSortedPlaylist([]);

      const inCategory = category !== undefined && category !== null;
      setAllItems(!inCategory);
      const gps = await loadGroup(playingMode);
      if (!gps || !gps.length) {
        history.replace("/");
        return;
      }
      if (playingMode === "movie") {
        gps.unshift({ category_id: "allmovies", category_name: "Svi Filmovi" });
      } else if (playingMode === "series") {
        gps.unshift({ category_id: "allseries", category_name: "Sve Serije" });
      }
      gps.unshift(
        { category_id: "fav", favorite: 1, category_name: "Favoriti" },
        { category_id: "toend", history: 1, category_name: "Nastavi gledati" }
      );
      dispatch(setGroupList(gps || []));
      const responseOutput = await axios.get("/output.json");
      const outputMovies = responseOutput.data;
      const responseApi = await loadPlaylist(
        playingMode,
        category || (playingMode === "movie" ? "allmovies" : "allseries")
      );
      let apiMovies = responseApi;
      if (category === "fav" || category === "toend") {
        apiMovies = apiMovies.filter((s) => {
          const f = DB.findOne(
            playingMode,
            playingMode === "series" ? s.series_id : s.stream_id,
            category === "fav"
          );
          return f && (category === "fav" || (f.tot > 3 && f.tot < 95));
        });
      }
      const mergedMovies = apiMovies.map((apiMovie) => {
        const outputMovie = outputMovies.find(
          (o) => parseInt(o.stream_id) === parseInt(apiMovie.stream_id)
        );
        if (outputMovie) {
          return mergeMovieData(apiMovie, outputMovie);
        } else {
          return {
            ...apiMovie,
            cast: parseArray(apiMovie.cast),
          };
        }
      });
      const validatedMovies = mergedMovies.map((movie) => ({
        ...movie,
        cast: Array.isArray(movie.cast) ? movie.cast : parseArray(movie.cast),
      }));
      const uniqueMovies = removeDuplicateMovies(validatedMovies);
      setStreamList(uniqueMovies);
      dispatch(setPlaylist(uniqueMovies));
      setIsLoading(false);
      showBlur(0);
    } catch (error) {
      console.error("Error fetching movies:", error);
      setIsLoading(false);
      setIsDataReady(false);
      showBlur(0);
    }
  }, [category, playingMode, dispatch, history, showBlur]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Apply genre and search filters whenever selectedGenre, searchText, or streamList changes
  useEffect(() => {
    setIsDataReady(false); // Data is not ready during filtering
    let newPlaylist = streamList;

    if (selectedGenre !== "") {
      newPlaylist = newPlaylist.filter((movie) => {
        return movie.genres && movie.genres.includes(selectedGenre);
      });
    }

    if (searchText !== "") {
      newPlaylist = newPlaylist.filter(
        (x) =>
          x.name.toLowerCase().includes(searchText.toLowerCase()) ||
          (Array.isArray(x.cast) &&
            x.cast.some((c) => c.toLowerCase().includes(searchText.toLowerCase())))
      );
    }

    if (newPlaylist.length === 0 && searchText !== "") {
      setShowPopup(true);
    } else {
      setShowPopup(false);
    }

    setPlaylistState(newPlaylist);
    localStorage.setItem("currentFilterCriteria", JSON.stringify(newPlaylist));
  }, [selectedGenre, searchText, streamList]);

  const toggleSortMode = (sortOption) => {
    if (sortMode !== sortOption) {
      setSortMode(sortOption);
      localStorage.setItem("currentSortMode", sortOption);
    }
  };

  const handleGenreChange = (genre) => {
    setSelectedGenre(genre);
    const params = new URLSearchParams(location.search);
    if (genre) {
      params.set("genre", genre);
    } else {
      params.delete("genre");
    }
    history.replace({ search: params.toString() });
  };

  useEffect(() => {
    const isInfoPage = location.pathname.match(/category\/[^/]+\/[^/]+\/info/);
    const isPlayPage = location.pathname.match(/\/play\//);
    setShowFilterInfo(!isInfoPage && !isPlayPage && !allItems);
    setIsModalOpen(!!isInfoPage || !!isPlayPage);
    showBlur(isInfoPage || isPlayPage ? 1 : 0);
  }, [location.pathname, showBlur, allItems]);

  const currentCategory = useMemo(() => {
    if (!category) return "";
    if (isNaN(category)) {
      return getOtherCategoryName(category, playingMode);
    }
    const foundCategory = categories.find((x) => parseInt(x.category_id) === parseInt(category));
    return foundCategory ? foundCategory.category_name : "";
  }, [category, categories, playingMode]);

  const mergeMovieData = (apiMovie, outputMovie) => {
    const releaseDate = outputMovie ? outputMovie.release_date : apiMovie.releasedate;
    let castArray = parseArray(outputMovie.cast);
    if (!Array.isArray(castArray)) {
      castArray = [castArray];
    }
    return {
      ...apiMovie,
      genres: parseArray(outputMovie.genres),
      cast: castArray,
      popularity: outputMovie.popularity || 0,
      overview: outputMovie.overview || "",
      rating: outputMovie.rating || apiMovie.rating || 0,
      release_date: releaseDate || apiMovie.release_date || "",
      stream_icon: apiMovie.stream_icon || outputMovie.stream_icon || null,
    };
  };

  // Use searchRandomMovie function to select a random movie
  const randomMovie = useMemo(() => {
    return searchRandomMovie(playlistState);
  }, [playlistState]);

  const sortMoviesByRating = useCallback((movies) => {
    return [...movies].sort((a, b) => {
      const aHasCover = a.stream_icon || a.cover;
      const bHasCover = b.stream_icon || b.cover;
      if (aHasCover && !bHasCover) return -1;
      if (!aHasCover && bHasCover) return 1;
      if (!aHasCover && !bHasCover) return 0;
      // Both have cover images, sort by rating
      return b.rating - a.rating;
    });
  }, []);

  const sortMoviesByPopularity = useCallback((movies) => {
    return [...movies].sort((a, b) => {
      const aHasCover = a.stream_icon || a.cover;
      const bHasCover = b.stream_icon || b.cover;
      if (aHasCover && !bHasCover) return -1;
      if (!aHasCover && bHasCover) return 1;
      if (!aHasCover && !bHasCover) return 0;
      // Both have cover images, sort by popularity
      return b.popularity - a.popularity;
    });
  }, []);

  const sortMoviesByReleaseDate = useCallback((movies) => {
    return [...movies].sort((a, b) => {
      const aHasCover = a.stream_icon || a.cover;
      const bHasCover = b.stream_icon || b.cover;
      if (aHasCover && !bHasCover) return -1;
      if (!aHasCover && bHasCover) return 1;
      if (!aHasCover && !bHasCover) return 0;
      // Both have cover images, sort by release date
      if (!a.release_date) return 1;
      if (!b.release_date) return -1;
      return new Date(b.release_date) - new Date(a.release_date);
    });
  }, []);

  const handleKeyNavigation = (event, rowIndex) => {
    const focusableElements = Array.from(document.querySelectorAll('[tabindex="0"]'));
    const itemsPerRow = 6;

    if (event.key === 'ArrowDown') {
      event.preventDefault();
      const nextRowFirstIndex = (rowIndex + 1) * itemsPerRow;
      if (focusableElements[nextRowFirstIndex]) {
        focusableElements[nextRowFirstIndex].focus();
      }
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      if (rowIndex === 0) {
        const navBar = document.querySelector('[data-navbar="true"]');
        if (navBar) {
          navBar.focus();
        }
      } else {
        const prevRowFirstIndex = (rowIndex - 1) * itemsPerRow;
        if (focusableElements[prevRowFirstIndex]) {
          focusableElements[prevRowFirstIndex].focus();
        }
      }
    }
  };

  const Row = ({ index, style }) => {
    if (index === 0) {
      return randomMovie && randomMovie.name ? (
        <div style={{ ...style }}>
          <BackgroundPlayer
            key={`bg-${randomMovie.stream_id || randomMovie.series_id}`}
            name={randomMovie.name}
            category_id={randomMovie.category_id}
            stream_id={randomMovie.stream_id || randomMovie.series_id}
            isSeries={playingMode === "series"}
            stream_icon={randomMovie.stream_icon || randomMovie.cover}
            existingTmdb={randomMovie.tmdb}
            overview={randomMovie.overview}
            title={
              category &&
              (isNaN(category)
                ? getOtherCategoryName(category, playingMode)
                : categories.find((x) => parseInt(x.category_id) === parseInt(category))
                    ?.category_name)
            }
            firstRowItemRef={firstRowItemRef}
            data-mainvod="true"
            onKeyDown={(e) => {
              if (e.key === "ArrowUp") {
                e.preventDefault();
                const navBar = document.querySelector('[data-navbar="true"]');
                if (navBar) {
                  navBar.focus();
                }
              }
            }}
          />
        </div>
      ) : (
        <div style={style}></div>
      );
    } else {
      const groupPlaylist = getGroupRowPlaylist(index);
      if (groupPlaylist.length === 0) {
        return null;
      }
      const categoryItem = categories[index - 1];
      const isContinueWatching = categoryItem.category_name === "Nastavi gledati";
      return (
        <div style={style}>
          <GroupRow
            key={categoryItem.category_id}
            category_id={categoryItem.category_id}
            name={categoryItem.category_name}
            isSeries={playingMode === "series"}
            playlist={groupPlaylist}
            style={{ paddingLeft: "1.8rem", textDecoration: "none" }}
            isFirst={index === 1}
            isContinueWatching={isContinueWatching}
            firstRowItemRef={index === 1 ? firstRowItemRef : null}
            sortMode={sortMode}
            onKeyNavigation={handleKeyNavigation}
            selectedGenre={selectedGenre}
            searchText={searchText} // Pass searchText to GroupRow
          />
        </div>
      );
    }
  };

  const getItemSize = (index) => {
    if (index === 0) return convertVhToPixels("70vh");
    else if (getGroupRowPlaylist(index).length > 0) {
      return convertVhToPixels("15vw") * 1.8;
    } else return 0;
  };

  const getRowHeight = (index) => convertVhToPixels("15vw") * 1.8;
  const getColumnWidth = (index) => convertVhToPixels("16.6vw");

  const Cell = ({ columnIndex, rowIndex, style }) => {
    const startIndex = rowIndex * 6;
    const endIndex = startIndex + 6;
    const displayedPlaylist = sortedPlaylist.slice(startIndex, endIndex);
    const item = displayedPlaylist[columnIndex];
    if (!item) return null;

    const itemRef = React.createRef();

    return (
      <RowItem
        ref={itemRef}
        id={startIndex + columnIndex}
        key={"vod" + (item.stream_id || item.series_id)}
        name={item.name}
        stream_icon={item.stream_icon || item.cover}
        stream_id={item.stream_id || item.series_id}
        isSeries={playingMode === "series"}
        category_id={item.category_id}
        existingTmdb={item.tmdb}
        style={{ ...style, padding: ".5rem" }}
        rowIndex={rowIndex}
        overview={item.overview}
        rating={item.rating}
        genres={parseArray(item.genres)}
        cast={parseArray(item.cast)}
        year={item.release_date ? item.release_date.slice(0, 4) : ""}
        onKeyNavigation={handleKeyNavigation}
        onMoreInfoOpen={() => setLastFocusedItem(itemRef)}
      />
    );
  };

  return (
    <div>
      <NavBar
        onSortToggle={toggleSortMode}
        sortMode={sortMode}
        onGenreChange={handleGenreChange}
        selectedGenre={selectedGenre}
        currentCategory={currentCategory}
        showFilterInfo={showFilterInfo}
      />
      <Route path="/:playingMode/">
        {isLoading && (
          <Spin>
            <Loading color={"var(--second-color);"}>
              <div>
                <div />
                <div />
                <div />
              </div>
            </Loading>
          </Spin>
        )}
        <Container style={blurBackground}>
          <GroupRowContainer style={{ paddingLeft: "0rem", paddingTop: 0 }}>
            {isDataReady && sortedPlaylist.length > 0 ? (
              allItems ? (
                <List
                  height={convertVhToPixels("100vh") - convertRemToPixels(4)}
                  itemCount={categories.length + 1}
                  itemSize={getItemSize}
                  useIsScrolling={true}
                  style={{ overflowX: "hidden" }}
                >
                  {Row}
                </List>
              ) : (
                <>
                  <div style={{ height: "2rem" }} />
                  <Grid
                    columnCount={6}
                    columnWidth={getColumnWidth}
                    height={convertVhToPixels("100vh") - convertRemToPixels(4)}
                    rowCount={Math.ceil(sortedPlaylist.length / 6)}
                    rowHeight={getRowHeight}
                    width={convertVhToPixels("100vw")}
                    useIsScrolling={true}
                    style={{ overflowX: "hidden" }}
                  >
                    {Cell}
                  </Grid>
                </>
              )
            ) : null}
          </GroupRowContainer>
        </Container>
        {showPopup && (
          <Popup
            title={`Nije pronađen stream s nazivom '${searchText}'`}
            description={"Pretraga će biti resetirana."}
            icon={"fas fa-search"}
            onclick={() => {
              setShowPopup(false);
              history.replace(location.pathname.split("?")[0]);
            }}
          />
        )}
        <Switch>
          <Route path="/movie/category/:category/:stream_id/play/">
            <PlayerVod />
          </Route>
          <Route path="/series/category/:category/:stream_id/play/season/:season/episode/:episode/">
            <PlayerSeries />
          </Route>
          <Route path="/:playingMode/category/:category/:stream_id/info/">
            <MoreInfo
              key={"info-" + playingMode + "-" + category + "-" + location.pathname}
              onClose={() => {
                if (lastFocusedItem && lastFocusedItem.current) {
                  lastFocusedItem.current.focus();
                }
              }}
            />
          </Route>
        </Switch>
      </Route>
    </div>
  );
};

export default MainVod;