import React, { useEffect, useCallback, useState } from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { PrivateRoute, ProvideAuth } from "./other/auth";
import "./App.css";
// Uklonjen NavBar import
import MainMenu from "./components/MainMenu/MainMenu";
import AccountInfo from "./components/AccountInfo";
import Login from "./components/Login";
import LateralBar from "./components/LateralBar/LateralBar";
import MainLive from "./components/Live/MainLive";
import Groups from "./components/Group/Groups";
import Search from "./components/Search/Search";
import EpgFullListing from "./components/Epg-Fullscreen/EpgFullListing";
import MainVod from "./components/Vod/MainVod";
import { useDispatch } from "react-redux";
import { setTimer60 } from "./actions/timer60";
import { setTimer5 } from "./actions/timer5";

function App() {
  const dispatch = useDispatch();
  const [sortByRating, setSortByRating] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  useEffect(() => {
    const interval60 = setInterval(() => dispatch(setTimer60()), 50000);
    const interval5 = setInterval(() => dispatch(setTimer5()), 5000);
    return () => {
      clearInterval(interval60);
      clearInterval(interval5);
    };
  }, [dispatch]);

  // Function to handle key navigation
  const handleKeyNavigation = useCallback((event) => {
    const focusableElements = Array.from(document.querySelectorAll('[tabindex="0"]'));
    const currentIndex = focusableElements.indexOf(document.activeElement);
    const itemsPerRow = 7;
    switch (event.key) {
      case "ArrowRight":
        if (currentIndex < focusableElements.length - 1) {
          focusableElements[currentIndex + 1].focus();
        }
        break;
      case "ArrowLeft":
        if (currentIndex > 0) {
          focusableElements[currentIndex - 1].focus();
        }
        break;
      case "ArrowDown":
        if (currentIndex + itemsPerRow < focusableElements.length) {
          focusableElements[currentIndex + itemsPerRow].focus();
        } else {
          // If there are no items in the next row, call handleArrowDown to focus the next row's first item
          handleArrowDown();
        }
        break;
      case "ArrowUp":
        if (currentIndex - itemsPerRow >= 0) {
          focusableElements[currentIndex - itemsPerRow].focus();
        }
        break;
      case "Enter":
      case "OK":
        if (document.activeElement) {
          document.activeElement.click();
        }
        break;
      default:
        break;
    }
    if (["ArrowUp", "ArrowDown"].includes(event.key)) {
      event.preventDefault();
    }
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyNavigation);
    return () => {
      window.removeEventListener("keydown", handleKeyNavigation);
    };
  }, [handleKeyNavigation]);

  // Function to focus the first item in the row below NavBar
  const handleArrowDown = () => {
    const firstRowItem = document.querySelector('[data-row="0"]'); // Assuming row items have data-row="1" for the first row
    if (firstRowItem) {
      firstRowItem.focus();
    }
  };

  const toggleSortByRating = () => {
    setSortByRating((prev) => !prev);
  };

  return (
    <ProvideAuth>
      <Router>
        <Switch>
          <Route>
            <Route path="/:playingMode/">
              <LateralBar />
            </Route>
            <PrivateRoute exact path="/">
              <MainMenu />
            </PrivateRoute>
            <Switch>
              <PrivateRoute exact path="/:playingMode/category/">
                <Groups />
              </PrivateRoute>
              <PrivateRoute exact path="/:playingMode/category/:category/">
              </PrivateRoute>
            </Switch>
            <Switch>
              <PrivateRoute exact path="/:playingMode/category/:category/search/">
                <Search />
              </PrivateRoute>
              <PrivateRoute exact path="/:playingMode/search/">
                <Search />
              </PrivateRoute>
            </Switch>
            <Switch>
              <PrivateRoute exact path="/live/category/:category/tvguide/">
                <EpgFullListing />
              </PrivateRoute>
              <PrivateRoute exact path="/live/category/:category/tvguide/:date">
                <EpgFullListing />
              </PrivateRoute>
            </Switch>
            <Switch>
              <Route path="/login/">
                <Login url={window.location.hash.replace("#", "")} />
              </Route>
              <PrivateRoute exact path="/info/">
                <AccountInfo />
              </PrivateRoute>
              <PrivateRoute path="/live/category/:category">
                <MainLive />
              </PrivateRoute>
              <PrivateRoute path="/live/">
                <MainLive />
              </PrivateRoute>
              <PrivateRoute path="/:playingMode/category/:category">
                <MainVod
                  sortByRating={sortByRating}
                  isFilterModalOpen={isFilterModalOpen}
                  setIsFilterModalOpen={setIsFilterModalOpen}
                />
              </PrivateRoute>
              <PrivateRoute path="/:playingMode/">
                <MainVod
                  sortByRating={sortByRating}
                  isFilterModalOpen={isFilterModalOpen}
                  setIsFilterModalOpen={setIsFilterModalOpen}
                />
              </PrivateRoute>
            </Switch>
          </Route>
        </Switch>
      </Router>
    </ProvideAuth>
  );
}

export default App;