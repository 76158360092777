// src/components/Vod/GroupRow.js

import React, { useCallback } from "react";
import styled from "styled-components";
import RowItem from "./RowItem";
import ShowMoreButton from "./ShowMoreButton";
import { Link, useHistory, useLocation } from "react-router-dom";
import PropTypes from 'prop-types';

const Li = styled.li`
  color: white;
  margin: 2rem 0;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 3rem;
  width: 100%;
  align-items: stretch;
  overflow-x: auto;
`;

const GroupHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const H3 = styled(Link)`
  font-size: 1.75rem;
  font-weight: bold;
  line-height: 1.2;
  margin: 0;
  text-decoration: none;
  color: white;
  padding: 0.5rem 0;
  display: inline-block;
  border-bottom: 3px solid ${({ theme }) => theme.colors.secondary};
  cursor: pointer;

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const maxItem = 5;

const GroupRow = ({
  category_id,
  name,
  style,
  playlist,
  isSeries,
  showAllItems,
  sortMode,
  isFirst,
  onKeyNavigation,
  selectedGenre,
  searchText,
}) => {
  const history = useHistory();
  const location = useLocation();

  const handleGroupClick = () => {
    history.push(
      `/${isSeries ? "series" : "movie"}/category/${category_id}/${location.search}`
    );
  };

  // Filter the playlist based on selectedGenre and searchText
  const filteredPlaylist = playlist.filter((item) => {
    let matchesGenre = true;
    let matchesSearch = true;

    if (selectedGenre) {
      matchesGenre = item.genres && item.genres.includes(selectedGenre);
    }

    if (searchText) {
      const searchLower = searchText.toLowerCase();
      const nameMatches = item.name.toLowerCase().includes(searchLower);
      const castMatches =
        item.cast &&
        item.cast.some((c) => c.toLowerCase().includes(searchLower));
      matchesSearch = nameMatches || castMatches;
    }

    return matchesGenre && matchesSearch;
  });

  const displayItems = showAllItems ? filteredPlaylist : filteredPlaylist.slice(0, maxItem);

  const showMore = !showAllItems && filteredPlaylist.length > maxItem;

  const handleKeyDown = useCallback((event, index) => {
    event.preventDefault();
    const focusableElements = Array.from(document.querySelectorAll('[tabindex="0"]'));
    const currentIndex = focusableElements.indexOf(document.activeElement);
    if (currentIndex === -1) return;

    switch (event.key) {
      case "ArrowRight":
        if (currentIndex < focusableElements.length - 1) {
          focusableElements[currentIndex + 1].focus();
        }
        break;
      case "ArrowLeft":
        if (currentIndex > 0) {
          focusableElements[currentIndex - 1].focus();
        }
        break;
      case "Enter":
        if (focusableElements[currentIndex].classList.contains("show-more-button")) {
          focusableElements[currentIndex].click();
        }
        break;
      default:
        break;
    }
  }, []);

  return (
    filteredPlaylist.length > 0 && (
      <Li style={style}>
        <GroupHeader>
          <H3 to={`/${isSeries ? "series" : "movie"}/category/${category_id}/`}>
            {name}
          </H3>
        </GroupHeader>
        <GridContainer>
          {displayItems.map((x, id) => (
            <RowItem
              id={id}
              key={"vod" + (x.stream_id || x.series_id)}
              name={x.name}
              stream_icon={x.stream_icon || x.cover}
              stream_id={x.stream_id || x.series_id}
              isSeries={isSeries}
              category_id={category_id}
              container_extension={x.container_extension}
              existingTmdb={x.tmdb}
              rowIndex={id}
              overview={x.overview}
              rating={x.rating}
              year={x.release_date ? x.release_date.slice(0, 4) : ''}
              genres={x.genres}
              cast={x.cast}
              onKeyNavigation={onKeyNavigation}
              isMainVod={isFirst && id === 0}
            />
          ))}
          {showMore && (
            <ShowMoreButton
              onClick={handleGroupClick}
              className="show-more-button"
              tabIndex="0"
            />
          )}
        </GridContainer>
      </Li>
    )
  );
};

// PropTypes
GroupRow.propTypes = {
  category_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  style: PropTypes.object,
  playlist: PropTypes.arrayOf(PropTypes.object).isRequired,
  isSeries: PropTypes.bool.isRequired,
  showAllItems: PropTypes.bool,
  sortMode: PropTypes.string.isRequired,
  isFirst: PropTypes.bool,
  onKeyNavigation: PropTypes.func.isRequired,
  selectedGenre: PropTypes.string,
  searchText: PropTypes.string,
};

export default GroupRow;