// src/components/Vod/BackgroundPlayer.js

import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { getVodInfo, getSeriesInfo } from '../../other/load-playlist';
import { optimizeName } from '../../other/vod-series-name-optimizer';

// Styled Components
const Container = styled.div`
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
`;

const BackgroundImage = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -2; /* Postavi ispod videa */
  background: 
  linear-gradient(to right, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.50) 70%, rgba(0, 0, 0, 0) 100%),
    ${({ backdropPath }) =>
      backdropPath ? `url("${backdropPath}") no-repeat center center` : 'none'};
  background-size: 50% 100%, cover;
  background-repeat: no-repeat, no-repeat;
  transition: opacity 0.5s ease-in-out;
  opacity: ${({ isLoaded }) => (isLoaded ? 1 : 0)};
`;

const VideoContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1; /* Postavi iznad background image */
  
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    pointer-events: none; /* Sprečava interakciju korisnika */
  }
`;

const Overlay = styled.div`
  position: absolute;
  bottom: 35%;
  left: 4%;
  z-index: 2;
  color: white;
  max-width: 36%;
  
  @media (max-width: 768px) {
    max-width: 80%;
    bottom: 30%;
    left: 5%;
  }

  @media (max-width: 576px) {
    max-width: 90%;
    bottom: 25%;
    left: 5%;
  }
`;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const Title = styled.h1`
  font-size: 3rem;
  font-weight: bold;
  margin-right: 1rem;
  
  @media (max-width: 768px) {
    font-size: 2rem;
  }

  @media (max-width: 576px) {
    font-size: 1.5rem;
  }
`;

const YearRating = styled.div`
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  
  @media (max-width: 768px) {
    font-size: 1.2rem;
  }

  @media (max-width: 576px) {
    font-size: 1rem;
  }
`;

const RatingBadge = styled.span`
  background-color: #46d369;
  color: black;
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
  margin-left: 1rem;
  font-weight: bold;
`;

const Desc = styled.p`
  font-size: 1rem;
  margin-bottom: 1.5rem;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  
  @media (max-width: 768px) {
    font-size: 0.9rem;
  }

  @media (max-width: 576px) {
    font-size: 0.8rem;
  }
`;

const ButtonsRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

const ButtonMain = styled(Link)`
  color: black !important;
  background-color: white;
  padding: 0.8rem 2rem;
  font-weight: bold;
  font-size: 1rem;
  margin-right: 1rem;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: rgba(255, 255, 255, 0.75);
  }
  
  @media (max-width: 768px) {
    padding: 0.6rem 1.5rem;
    font-size: 0.9rem;
  }
  
  @media (max-width: 576px) {
    padding: 0.4rem 1rem;
    font-size: 0.8rem;
  }
`;

const ButtonSecond = styled(Link)`
  color: white !important;
  background-color: rgba(109, 109, 110, 0.7);
  padding: 0.8rem 2rem;
  font-weight: bold;
  font-size: 1rem;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: rgba(109, 109, 110, 0.4);
  }
  
  @media (max-width: 768px) {
    padding: 0.6rem 1.5rem;
    font-size: 0.9rem;
  }
  
  @media (max-width: 576px) {
    padding: 0.4rem 1rem;
    font-size: 0.8rem;
  }
`;

const Shadow = styled.div`
  position: absolute;
  width: 100%;
  height: 7rem;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-image: linear-gradient(to top, #141414, transparent);
`;

const BackgroundPlayer = React.memo(
  ({
    name,
    stream_icon,
    stream_id,
    category_id,
    isSeries,
    style,
    existingTmdb,
    firstRowItemRef,
  }) => {
    const [Name, setName] = useState(optimizeName(name));
    const [Description, setDescription] = useState('');
    const [Year, setYear] = useState('');
    const [Rating, setRating] = useState('');
    const [youtubeId, setYoutubeId] = useState(null);
    const [backdropPath, setBackdropPath] = useState(null);
    const [showVideo, setShowVideo] = useState(false);
    const [isBackdropLoaded, setIsBackdropLoaded] = useState(false);
    const [playerReady, setPlayerReady] = useState(false);
    const playButtonRef = useRef(null);
    const infoButtonRef = useRef(null);
    const playerRef = useRef(null);

    // Function to load YouTube Iframe API
    const loadYouTubeIframeAPI = () => {
      return new Promise((resolve) => {
        if (window.YT && window.YT.Player) {
          resolve(window.YT);
        } else {
          const existingScript = document.getElementById('youtube-iframe-api');
          if (!existingScript) {
            const tag = document.createElement('script');
            tag.id = 'youtube-iframe-api';
            tag.src = 'https://www.youtube.com/iframe_api';
            document.body.appendChild(tag);
          }
          window.onYouTubeIframeAPIReady = () => {
            resolve(window.YT);
          };
        }
      });
    };

    // useEffect to fetch data
    useEffect(() => {
      let isMounted = true;
      async function fetchData() {
        const result = await (isSeries
          ? getSeriesInfo(stream_id, name, true, existingTmdb)
          : getVodInfo(stream_id, name, existingTmdb));
        if (result && result.info && isMounted) {
          const info = result.info;
          console.log('Fetched info:', info);
          info.name && setName(info.name);
          info.description && setDescription(info.description);

          // Extract YouTube ID
          if (info.youtube_trailer) {
            console.log('Original youtube_trailer:', info.youtube_trailer);
            const extractedId = extractYoutubeId(info.youtube_trailer);
            console.log('Extracted youtubeId:', extractedId);
            setYoutubeId(extractedId);
          } else {
            console.log('youtube_trailer nije dostupan');
          }

          info.backdrop_path &&
            info.backdrop_path.length > 0 &&
            setBackdropPath(info.backdrop_path[0]);

          // Get year
          if (info.release_date) {
            setYear(new Date(info.release_date).getFullYear());
          } else if (info.year) {
            setYear(info.year);
          } else if (info.aired) {
            setYear(new Date(info.aired).getFullYear());
          } else if (info.releasedate) {
            setYear(new Date(info.releasedate).getFullYear());
          }

          // Get rating
          if (info.rating) {
            setRating(info.rating);
          } else if (info.vote_average) {
            setRating(info.vote_average);
          }
        }
      }
      fetchData();
      return () => {
        isMounted = false;
      };
    }, [stream_id, name, isSeries, existingTmdb]);

    // Function to extract YouTube ID
    function extractYoutubeId(url) {
      const regex = /(?:youtube\.com\/(?:.*v=|.*\/)|youtu\.be\/)([^#&?]*).*/i;
      const match = url.match(regex);
      if (match && match[1].length === 11) {
        return match[1];
      } else if (url.length === 11) {
        return url; // If it's just the ID
      } else {
        return null;
      }
    }

    // useEffect to initialize YouTube player
    useEffect(() => {
      let isMounted = true;
      if (youtubeId) {
        console.log('Initializing YouTube player with ID:', youtubeId);
        loadYouTubeIframeAPI().then((YT) => {
          if (!isMounted) return;

          playerRef.current = new YT.Player(`player-${youtubeId}`, {
            videoId: youtubeId,
            playerVars: {
              autoplay: 0,
              controls: 0, // Hide controls
              mute: 1,
              disablekb: 1,
              modestbranding: 1, // Minimal branding
              rel: 0, // No related videos
              iv_load_policy: 3, // Hide annotations
              fs: 0, // Disable fullscreen
              playsinline: 1,
              showinfo: 0, // Deprecated but may still work
              loop: 0,
              playlist: youtubeId, // Required for loop:0
            },
            events: {
              onReady: onPlayerReady,
              onStateChange: onPlayerStateChange,
            },
          });
        });
      }

      return () => {
        isMounted = false;
        if (playerRef.current) {
          playerRef.current.destroy();
          playerRef.current = null;
        }
      };
    }, [youtubeId]);

    const onPlayerReady = (event) => {
      console.log('Player ready');
      setPlayerReady(true);
    };

    const onPlayerStateChange = (event) => {
      console.log('Player state changed:', event.data);
      if (event.data === window.YT.PlayerState.ENDED) {
        console.log('Video ended');
        setShowVideo(false);
      }
    };

    // useEffect to start video after 20 seconds
    useEffect(() => {
      let isMounted = true;
      let timer;
      if (youtubeId && playerReady) {
        console.log('youtubeId is set, starting timer to show video');
        timer = setTimeout(() => {
          if (isMounted) {
            setShowVideo(true);
            console.log('showVideo set to true');
            if (playerRef.current) {
              playerRef.current.playVideo();
            }
          }
        }, 20000); // Show video after 20 seconds
      }

      return () => {
        clearTimeout(timer);
        isMounted = false;
      };
    }, [youtubeId, playerReady]);

    // useEffect to preload the background image
    useEffect(() => {
      let isMounted = true;
      if (backdropPath) {
        const img = new Image();
        img.src = backdropPath;
        img.onload = () => {
          if (isMounted) {
            setIsBackdropLoaded(true);
            console.log('Backdrop image loaded');
          }
        };
      }
      return () => {
        isMounted = false;
      };
    }, [backdropPath]);

    // Function to handle key presses
    const handleKeyDown = useCallback(
      (event) => {
        if (event.key === 'ArrowDown') {
          if (document.activeElement === playButtonRef.current) {
            firstRowItemRef.current && firstRowItemRef.current.focus();
          } else if (document.activeElement === infoButtonRef.current) {
            playButtonRef.current.focus();
          }
        } else if (event.key === 'ArrowUp') {
          if (document.activeElement === playButtonRef.current) {
            // Fokus na navigaciju ili prethodni element
          } else if (document.activeElement === firstRowItemRef.current) {
            playButtonRef.current.focus();
          }
        }
      },
      [firstRowItemRef]
    );

    useEffect(() => {
      window.addEventListener('keydown', handleKeyDown);
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }, [handleKeyDown]);

    return (
      <Container className="parent" style={style}>
        {youtubeId && (
          <VideoContainer style={{ display: showVideo ? 'block' : 'none' }}>
            <div id={`player-${youtubeId}`}></div>
          </VideoContainer>
        )}
        {!showVideo && isBackdropLoaded ? (
          <BackgroundImage
            backdropPath={backdropPath}
            isLoaded={isBackdropLoaded}
          />
        ) : null}
        <Overlay>
          <TitleRow>
            <Title>{Name}</Title>
            <YearRating>
              {Year && <span>{Year}</span>}
              {Rating && <RatingBadge>{Rating}</RatingBadge>}
            </YearRating>
          </TitleRow>
          <Desc>{Description}</Desc>
          <ButtonsRow>
            {!isSeries && (
              <ButtonMain
                to={`/movie/category/${category_id}/${stream_id}/play/`}
                ref={playButtonRef}
              >
                <i
                  className="fas fa-play"
                  style={{ marginRight: '0.5rem' }}
                ></i>
                Reproduciraj
              </ButtonMain>
            )}
            {isSeries && (
              <ButtonMain
                to={`/series/category/${category_id}/${stream_id}/play/season/1/episode/1/`}
                ref={playButtonRef}
              >
                <i
                  className="fas fa-play"
                  style={{ marginRight: '0.5rem' }}
                ></i>
                Reproduciraj
              </ButtonMain>
            )}
            <ButtonSecond
              to={`/${isSeries ? 'series' : 'movie'}/category/${category_id}/${stream_id}/info/`}
              ref={infoButtonRef}
            >
              <i
                className="fas fa-info-circle"
                style={{ marginRight: '0.5rem' }}
              ></i>
              Više informacija
            </ButtonSecond>
          </ButtonsRow>
        </Overlay>
        <Shadow />
      </Container>
    );
  }
);

export default BackgroundPlayer;