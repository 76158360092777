import React, { forwardRef } from "react";
import styled from "styled-components";
import { FaChevronDown } from "react-icons/fa";
import PropTypes from "prop-types";

const ButtonWrapper = styled.button`
 flex: 0 1 calc(14% - 1rem); // Occupy same space as other items (6 items per row)
 max-width: calc(14% - 1rem);
 height: 59%; // Ensures the button takes the same height as RowItems
 text-align: center;
 padding: 0;
 cursor: pointer;
 outline-width: 0;
 text-decoration: none !important;
 transition: transform 0.3s ease, box-shadow 0.3s ease;
 position: relative;
 z-index: 1;
 margin: 0 0.5rem 1.5rem 0.5rem;
 background-color: transparent;
 border: none;
 color: white;
 &:hover,
 &:focus {
   transform: scale(1.1);
   transform-origin: center center;
   outline: none;
   z-index: 100;
   color: #FF0000;
 }
`;

const Icon = styled(FaChevronDown)`
 font-size: 3rem;
 margin-top: 1rem;
`;



const ShowMoreButton = forwardRef(({ onClick, onKeyDown }, ref) => {
 return (
   <ButtonWrapper
     onClick={onClick}
     onKeyDown={onKeyDown}
     className="show-more-button"
     ref={ref}
     tabIndex="0"
   >
     <Icon />

   </ButtonWrapper>
 );
});

// PropTypes validation
ShowMoreButton.propTypes = {
 onClick: PropTypes.func.isRequired,
 onKeyDown: PropTypes.func,
};

export default ShowMoreButton;